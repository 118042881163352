import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-svg-gauge',
  templateUrl: './svg-gauge.component.html',
  styleUrls: ['./svg-gauge.component.scss']
})
export class SvgGaugeComponent implements OnInit {
  @Input() value = 0;
  strokeDashoffset = 659.4;
  meterRotation = -90;
  pathData='';
  constructor() {}
 // Function to convert degrees to radians
  toRadians(angle) {
    return (Math.PI / 180) * angle;
  }

  updateGauge(value) {
    const circumference = 659.4;  // Circumference of the circle
    const offset = (value / 100) * circumference;
    this.strokeDashoffset = offset;
    this.meterRotation = -125 + (value / 100) * 252;
    let path;
    for (let i = 1; i <= value; i++) {
      path = this.generateArc((i - 1) / 100 , i / 100);
      this.pathData+=path;
    }
    
  }

  generateArc(startPercent, endPercent) {
    const startAngle = startPercent * 251 - 215; // Convert percentage to degrees
    const endAngle = endPercent * 251 - 215;

    // Convert angles to radians
    const startRad = this.toRadians(startAngle);
    const endRad = this.toRadians(endAngle);

    // Start and end points
    const x1 = 200 + 150 * Math.cos(startRad);
    const y1 = 200 + 150 * Math.sin(startRad);
    const x2 = 200 + 150 * Math.cos(endRad);
    const y2 = 200 + 150 * Math.sin(endRad);

    // Determine if the arc is large (> 180 degrees)
    const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0;

    // Return the path data for the arc
    return `M ${x1},${y1} A ${150},${150} 0 ${largeArcFlag} 1 ${x2},${y2}`;
  }

  OnChanges() {
    this.updateGauge(this.value);
  }

  ngOnInit(): void {
    this.updateGauge(this.value);
  }

}
